<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">Salary Billing</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-2"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="selection"
            class="mx-2"
            dense
            outlined
            label="Selection"
            :items="['1-15','16-30/31','Incentives']"
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
          v-if="position==='ADMIN'||position==='FINANCE'||position==='AUDIT'||position==='HUMAN RESOURCE'"
        >
          <v-select
            outlined
            class="mx-2"
            v-model="category_id"
            dense
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            :rules="rules.combobox_rule"
            @change="selected_month"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" md="12"
               v-if="payroll_data.length===0&&payroll_data2.length===0&&payroll_data3.length===0&&payroll_data4.length===0&&payroll_data5.length===0&&payroll_data6.length===0&&payroll_data7.length===0&&payroll_data8.length===0&&payroll_data_coop_loan.length===0&&payroll_data_sss_loan.length===0&&payroll_data_pagIbig_loan.length===0&&payroll_data_philHealth.length===0&&payroll_data_pagIbig.length===0&&payroll_data_tax.length===0&&payroll_data_foundation_share.length===0">
          <v-btn
            class="w-full"
            color="primary"
            @click="save_request"
            v-if="!saving"
          >
            Generate Billings
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-if="saving"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in position==='ADMIN'||position==='FINANCE'?tabs:(position==='AUDIT'?tabs1:
          (position==='HUMAN RESOURCE'?tabs3:[]))"
          :key="tab.title"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE'">
          <v-data-table dense
                        :headers="headers"
                        :items="payroll_data"
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">

                <h2 v-if="!printingss && payroll_data.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Auto Loan')"
                         v-if="!approved && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data,'AUTO LOANS DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data,'AUTO LOANS DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ item.details!=null?item.details.unit:''}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ formatPrice(item.balance) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE'">
          <v-data-table dense
                        :headers="headers2"
                        :items="payroll_data2"
                        :search="search2"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data2.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Cash Advance')"
                         v-if="!approved2 && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data2,'CASH ADVANCE DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data2,'CASH ADVANCE DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search2"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.details!=null?
                  (item.details.cv!=null?'CV#'+item.details.cv.voucher_no:
                  (item.details.ckv!=null?'CKV#'+item.details.ckv.voucher_no:''))
                  :''}}
                </td>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ item.details!=null?item.details.description:''}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ formatPrice(item.balance) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE'">
          <v-data-table dense
                        :headers="headers3"
                        :items="payroll_data3"
                        :search="search3"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data3.length > 0">
                  <v-btn class="mt-2" color="success"
                         @click="approved_deductions('Accounting Loan')"
                         v-if="!approved3 && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data3,'ACCOUNTING LOAN DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data3,'ACCOUNTING LOAN DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search3"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ item.details!=null?item.details.purpose:''}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ formatPrice(item.balance) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE'">
          <v-data-table dense
                        :headers="headers4"
                        :items="payroll_data4"
                        :search="search4"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data4.length > 0">
                  <v-btn class="mt-2" color="success"
                         @click="approved_deductions('Other Receivable')"
                         v-if="!approved4 && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data4,'OTHER RECEIVABLE DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    color="info"
                    v-if="!is_final"
                    @click="print_data(payroll_data4,'OTHER RECEIVABLE DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search4"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ item.details!=null?item.details.purpose:''}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ formatPrice(item.balance) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE'">
          <v-data-table dense
                        :headers="headers5"
                        :items="payroll_data5"
                        :search="search5"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data5.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Motor Finance')"
                         v-if="!approved5 && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data5,'MOTOR FINANCE DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data5,'MOTOR FINANCE DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search5"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ item.details!=null?item.details.purpose:''}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ formatPrice(item.balance) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE'">
          <v-data-table dense
                        :headers="headers8"
                        :items="payroll_data_coop_loan"
                        :search="search_coop_loan"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data_coop_loan.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Coop Loan')"
                         v-if="!approved_coop_loan && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data_coop_loan,'COOP LOAN DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data_coop_loan,'COOP LOAN DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search5"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE'">
          <v-data-table dense
                        :headers="headers8"
                        :items="payroll_data_foundation_share"
                        :search="search_foundation_share"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data_foundation_share.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Foundation Share')"
                         v-if="!approved_foundation_share && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data_foundation_share,'FOUNDATION SHARE DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data_foundation_share,'FOUNDATION SHARE DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search5"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE'">
          <v-data-table dense
                        :headers="headers8"
                        :items="payroll_data_tax"
                        :search="search_tax"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data_tax.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Tax')"
                         v-if="!approved_tax && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data_tax,'TAX DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data_tax,'TAX DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search5"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE' || position==='AUDIT'">
          <v-data-table dense
                        :headers="headers6"
                        :items="payroll_data6"
                        :search="search6"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data6.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Plan')"
                         v-if="!approved6 && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data6,'PLAN DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data6,'PLAN DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search6"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='FINANCE' || position==='HUMAN RESOURCE'">
          <v-data-table dense
                        :headers="headers8"
                        :items="payroll_data8"
                        :search="search8"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data8.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('SSS')"
                         v-if="!approved6 && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data8,'SSS DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data8,'SSS DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search6"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='HUMAN RESOURCE'">
          <v-data-table dense
                        :headers="headers8"
                        :items="payroll_data_sss_loan"
                        :search="search_sss_loan"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data_sss_loan.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('SSS Loan')"
                         v-if="!approved_sss_loan && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data_sss_loan,'SSS LOAN DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data_sss_loan,'SSS LOAN DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search5"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='HUMAN RESOURCE'">
          <v-data-table dense
                        :headers="headers8"
                        :items="payroll_data_pagIbig_loan"
                        :search="search_pagIbig_loan"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data_pagIbig_loan.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Pag-ibig Loan')"
                         v-if="!approved_pagIbig_loan && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data_pagIbig_loan,'PAG-IBIG LOAN DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data_pagIbig_loan,'PAG-IBIG LOAN DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search5"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='HUMAN RESOURCE'">
          <v-data-table dense
                        :headers="headers8"
                        :items="payroll_data_philHealth"
                        :search="search_philHealth"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data_philHealth.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('PhilHealth')"
                         v-if="!approved_philHealth && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data_philHealth,'PHILHEALTH DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data_philHealth,'PHILHEALTH DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search5"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item v-if="position==='ADMIN'||position==='HUMAN RESOURCE'">
          <v-data-table dense
                        :headers="headers8"
                        :items="payroll_data_pagIbig"
                        :search="search_pagIbig"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <h2 v-if="!printingss && payroll_data_pagIbig.length > 0">
                  <v-btn class="mt-2" color="success" @click="approved_deductions('Pag-ibig')"
                         v-if="!approved_pagIbig && !is_final">
                    Final/Approved
                  </v-btn>
                  <v-progress-circular
                    :size="50"
                    :width="5"
                    color="primary"
                    indeterminate
                    v-if="is_final"
                  ></v-progress-circular>
                  Print Billed |
                  <v-icon
                    v-if="!is_final"
                    color="success"
                    @click="print_data(payroll_data_pagIbig,'PAG-IBIG DEDUCTIONS','BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  Print Un-Billed |
                  <v-icon
                    v-if="!is_final"
                    color="info"
                    @click="print_data(payroll_data_pagIbig,'PAG-IBIG DEDUCTIONS','UN-BILLED')"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </h2>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearch"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search5"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.name}}
                </td>
                <td>
                  {{ item.branch}}
                </td>
                <td>
                  {{ item.position}}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  <v-icon
                    v-if="item.is_approved===0"
                    class="mr-2"
                    color="error"
                    @click="edit_details(item.id)"
                  >
                    {{icons.mdiLeadPencil}}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="edit_dialog" max-width="80%" :key="this.key">
      <v-card>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-card
            flat
          >
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">EDIT AMOUNT</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col
                md="12"
                cols="12"
              ></v-col>
              <v-row>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-text-field
                    v-model="amount_to_edit"
                    label="Amount"
                    type="number"
                    dense
                    outlined
                    min="0"
                    :rules="rules.default_no_empty_rule"
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>

          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"

            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click="update_details"
              v-if="!saving"
            >
              Update changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiAccountSearch, mdiGoogleCirclesCommunities, mdiCharity, mdiGift, mdiHandshakeOutline,
    mdiLeadPencil,
    mdiPrinter,
    mdiCashMultiple,
    mdiCurrencyUsd,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      edit_dialog: false,
      is_final: false,
      approved: false,
      approved2: false,
      approved3: false,
      approved4: false,
      approved5: false,
      approved6: false,
      approved7: false,
      approved8: false,
      approved_coop_loan: false,
      approved_sss_loan: false,
      approved_pagIbig_loan: false,
      approved_pagIbig: false,
      approved_philHealth: false,
      approved_tax: false,
      approved_foundation_share: false,
      key: 0,
      selection: '',
      status: '',
      month_of: '',
      month_of_items: [],
      payroll_data: [],
      payroll_data2: [],
      payroll_data3: [],
      payroll_data4: [],
      payroll_data5: [],
      payroll_data6: [],
      payroll_data7: [],
      payroll_data8: [],
      payroll_data_coop_loan: [],
      payroll_data_sss_loan: [],
      payroll_data_pagIbig_loan: [],
      payroll_data_philHealth: [],
      payroll_data_pagIbig: [],
      payroll_data_tax: [],
      payroll_data_foundation_share: [],
      category_id: '',
      category_items: [],
      search: '',
      search2: '',
      search3: '',
      search4: '',
      search5: '',
      search6: '',
      search7: '',
      search8: '',
      search_coop_loan: '',
      search_sss_loan: '',
      search_pagIbig_loan: '',
      search_pagIbig: '',
      search_philHealth: '',
      search_tax: '',
      search_foundation_share: '',
      amount_to_edit: '0',
      id_deductions: 0,

      printingss: false,

      saving: false,
      alert: false,
      alert_message: '',

      tab: '',
      tabs: [
        {title: 'AUTO LOAN', icon: mdiGoogleCirclesCommunities},
        {title: 'CASH ADVANCE', icon: mdiAccountOutline},
        {title: 'ACCOUNTING LOAN', icon: mdiCharity},
        {title: 'OTHER RECEIVABLE', icon: mdiGift},
        {title: 'MOTOR FINANCE', icon: mdiHandshakeOutline},
        {title: 'COOP LOAN', icon: mdiHandshakeOutline},
        {title: 'FOUNDATION SHARE', icon: mdiHandshakeOutline},
        {title: 'TAX', icon: mdiHandshakeOutline},
        {title: 'PLAN', icon: mdiHandshakeOutline},
        {title: 'SSS', icon: mdiHandshakeOutline},
        {title: 'SSS LOAN', icon: mdiHandshakeOutline},
        {title: 'PAG-IBIG LOAN', icon: mdiHandshakeOutline},
        {title: 'PHILHEALTH', icon: mdiHandshakeOutline},
        {title: 'PAG-IBIG', icon: mdiHandshakeOutline},
      ],
      tabs1: [
        {title: 'PLAN', icon: mdiHandshakeOutline},
      ],
      tabs3: [
        {title: 'SSS', icon: mdiHandshakeOutline},
        {title: 'SSS LOAN', icon: mdiHandshakeOutline},
        {title: 'PAG-IBIG LOAN', icon: mdiHandshakeOutline},
        {title: 'PHILHEALTH', icon: mdiHandshakeOutline},
        {title: 'PAG-IBIG', icon: mdiHandshakeOutline},
      ],
      tabs2: [
        {title: 'COOP SAVE', icon: mdiCashMultiple},
      ],
      headers: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch', value: 'name', sortable: false},
        {text: 'Position', value: 'name', sortable: false},
        {text: 'Description', value: 'name', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Balance', value: 'amount', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers2: [
        {text: 'Voucher #', value: 'name', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch', value: 'name', sortable: false},
        {text: 'Position', value: 'name', sortable: false},
        {text: 'Description', value: 'description', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Balance', value: 'amount', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers3: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch', value: 'name', sortable: false},
        {text: 'Position', value: 'name', sortable: false},
        {text: 'Description', value: 'name', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Balance', value: 'amount', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers4: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch', value: 'name', sortable: false},
        {text: 'Position', value: 'name', sortable: false},
        {text: 'Description', value: 'name', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Balance', value: 'amount', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers5: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch', value: 'name', sortable: false},
        {text: 'Position', value: 'name', sortable: false},
        {text: 'Description', value: 'name', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Balance', value: 'amount', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers6: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch', value: 'name', sortable: false},
        {text: 'Position', value: 'name', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
      headers7: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch', value: 'name', sortable: false},
        {text: 'Position', value: 'name', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],

      headers8: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch', value: 'name', sortable: false},
        {text: 'Position', value: 'name', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Action', value: 'amount', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
          mdiLeadPencil,
          mdiPrinter,
          mdiCashMultiple,
          mdiCurrencyUsd,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['name', 'employee_id', 'position', 'is_head']),
      ...mapGetters('authentication', [
        'employee_id',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salary_deductions', ['initialize_salary_deductions', 'salary_deductions_data', 'update_deductions_data', 'approve_salary_deductions']),
      initialize_data() {
        this.initialize_salary_deductions()
          .then(response => {
            this.month_of_items = response.data[0].month_of
            this.category_items = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      edit_details(value) {
        this.id_deductions = value
        this.edit_dialog = true
        this.key++
      },
      selected_month() {
        this.payroll_data = []
        this.payroll_data2 = []
        this.payroll_data3 = []
        this.payroll_data4 = []
        this.payroll_data5 = []
        this.payroll_data6 = []
        this.payroll_data7 = []
        this.payroll_data8 = []
        this.payroll_data_coop_loan = []
        this.payroll_data_sss_loan = []
        this.payroll_data_pagIbig_loan = []
        this.payroll_data_pagIbig = []
        this.payroll_data_philHealth = []
        this.approved = false
        this.approved2 = false
        this.approved3 = false
        this.approved4 = false
        this.approved5 = false
        this.approved6 = false
        this.approved7 = false
        this.approved8 = false
        this.approved_coop_loan = false
        this.approved_sss_loan = false
        this.approved_pagIbig_loan = false
        this.approved_pagIbig = false
        this.approved_philHealth = false
      },
      update_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.id_deductions);
          data.append('amount', this.amount_to_edit);
          this.update_deductions_data(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.id_deductions = 0
              this.edit_dialog = false
              this.alert = false
              this.alert_message = ''
              this.saving = false
              this.save_request()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      save_request() {
        const data = new FormData()
        data.append('month_of_id', this.month_of);
        data.append('category_id', this.category_id);
        data.append('duration', this.selection);
        data.append('position', this.position);
        data.append('employee_id', this.employee_id);
        this.salary_deductions_data(data)
          .then(response => {
            this.payroll_data = response.data[0].auto_loan
            this.payroll_data2 = response.data[0].cash_advance
            this.payroll_data3 = response.data[0].act_loan
            this.payroll_data4 = response.data[0].o_rec
            this.payroll_data5 = response.data[0].motor
            this.payroll_data6 = response.data[0].plan
            this.payroll_data7 = response.data[0].coop_save
            this.payroll_data8 = response.data[0].sss
            this.payroll_data_coop_loan = response.data[0].coop_loan
            this.payroll_data_sss_loan = response.data[0].sss_loan
            this.payroll_data_pagIbig_loan = response.data[0].pagibig_loan
            this.payroll_data_pagIbig = response.data[0].pagibig
            this.payroll_data_philHealth = response.data[0].philHealth
            this.payroll_data_tax = response.data[0].tax
            this.payroll_data_foundation_share = response.data[0].foundation_share
            this.approved = this.payroll_data.length > 0 ? this.payroll_data[0].is_approved === 1 : false
            this.approved2 = this.payroll_data2.length > 0 ? this.payroll_data2[0].is_approved === 1 : false
            this.approved3 = this.payroll_data3.length > 0 ? this.payroll_data3[0].is_approved === 1 : false
            this.approved4 = this.payroll_data4.length > 0 ? this.payroll_data4[0].is_approved === 1 : false
            this.approved5 = this.payroll_data5.length > 0 ? this.payroll_data5[0].is_approved === 1 : false
            this.approved6 = this.payroll_data6.length > 0 ? this.payroll_data6[0].is_approved === 1 : false
            this.approved7 = this.payroll_data7.length > 0 ? this.payroll_data7[0].is_approved === 1 : false
            this.approved8 = this.payroll_data8.length > 0 ? this.payroll_data8[0].is_approved === 1 : false
            this.approved_coop_loan = this.payroll_data_coop_loan.length > 0 ? this.payroll_data_coop_loan[0].is_approved === 1 : false
            this.approved_sss_loan = this.payroll_data_sss_loan.length > 0 ? this.payroll_data_sss_loan[0].is_approved === 1 : false
            this.approved_pagIbig_loan = this.payroll_data_pagIbig_loan.length > 0 ? this.payroll_data_pagIbig_loan[0].is_approved === 1 : false
            this.approved_pagIbig = this.payroll_data_pagIbig.length > 0 ? this.payroll_data_pagIbig[0].is_approved === 1 : false
            this.approved_philHealth = this.payroll_data_philHealth.length > 0 ? this.payroll_data_philHealth[0].is_approved === 1 : false
            this.approved_tax = this.payroll_data_tax.length > 0 ? this.payroll_data_tax[0].is_approved === 1 : false
            this.approved_foundation_share = this.payroll_data_foundation_share.length > 0 ? this.payroll_data_foundation_share[0].is_approved === 1 : false

          })
          .catch(error => {
            console.log(error)
          })
      },
      approved_deductions(value) {
        this.final = true
        this.approve_salary_deductions({
          duration: this.selection,
          month_of_id: this.month_of,
          type: value,
          category_id: this.category_id
        }).then(response => {
          this.change_snackbar({
            show: true,
            color: 'success',
            text: response.data,
          })
          this.final = false
          this.save_request()
        })
          .catch(error => {
            console.log(error)
          })
      },
      async print_data(value, titl, status) {
        this.printingss = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printingss_ = this.printing

        var is_caaa = (titl === 'CASH ADVANCE DEDUCTIONS');
        if (is_caaa) {
          widths = [60, 130, 200, 60, 60]
          payments_array.push([
            {text: 'Voucher #', alignment: 'left', style: 'main_info'},
            {text: 'Name', alignment: 'left', style: 'main_info'},
            {text: 'Description', alignment: 'left', style: 'main_info'},
            {text: 'Amount', alignment: 'left', style: 'main_info'},
            {text: 'Balance', alignment: 'left', style: 'main_info'},
          ])
        } else {
          widths = [200, 150, 80, 80]
          payments_array.push([
            {text: 'Name', alignment: 'left', style: 'main_info'},
            {text: 'Description', alignment: 'left', style: 'main_info'},
            {text: 'Amount', alignment: 'left', style: 'main_info'},
            {text: 'Balance', alignment: 'left', style: 'main_info'},
          ])
        }

        var tott = 0
        value.forEach(function (item, index, payment) {
          var proceed = false;
          if (status === 'BILLED') {
            proceed = parseFloat(item.amount) > 0;
          } else {
            proceed = parseFloat(item.amount) === 0;
          }
          if (proceed) {
            tott += parseFloat(item.amount)
            if (is_caaa) {
              payments_array.push([
                {
                  text: item.details != null ?
                    (item.details.cv != null ? 'CV#' + item.details.cv.voucher_no :
                      (item.details.ckv != null ? 'CKV#' + item.details.ckv.voucher_no : ''))
                    : '',
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: item.name,
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: item.details != null ? item.details.description : '',
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: (item.balance / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
              ])
            } else {
              payments_array.push([
                {
                  text: item.name,
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: item.details != null ? (titl === 'AUTO LOANS DEDUCTIONS' ? item.details.unit : item.details.purpose) : '',
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: (item.balance / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
              ])
            }
          }


          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            if (is_caaa) {
              payments_array.push([
                {
                  text: '',
                }, {
                  text: '',
                },
                {
                  text: 'TOTAL',
                  alignment: 'right',
                  style: 'tableExample2',
                },
                {
                  text: (tott / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: '',
                },
              ])
            } else {
              payments_array.push([

                {
                  text: '',
                },
                {
                  text: 'TOTAL',
                  alignment: 'right',
                  style: 'tableExample2',
                },
                {
                  text: (tott / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left',
                  style: 'tableExample2',
                },
                {
                  text: '',
                },
              ])
            }
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'DURATION: ',
                  {
                    text: this.selection,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CATEGORY: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: titl,
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printingss_, width: 34, height: 20, style: 'logo'},
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printingss = false
      }
    },
  }
</script>
